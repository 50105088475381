import React, { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import auth from "../services/auth";
import { useLocalStorage } from "./useLocalStorage";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
	const [user, setUser] = useLocalStorage("scuser", null);
	const [walletconnect, setWalletconnect] = useLocalStorage(
		"walletconnect",
		null
	);

	const navigate = useNavigate();

	React.useEffect(() => {}, []);

	const _setUser = async (response) => {
		if (response) {
			auth.setAuthorizationToken(response.sctoken);
			setUser(response);
		}
	};

	const login = async (response, type) => {
		if (response) {
			setUser(response);

			// let redirect_link = localStorage.getItem("sc_auth_redirect");
			// if (redirect_link) {
			// 	console.log(redirect_link);
			// 	localStorage.removeItem("sc_auth_redirect");
			// 	navigate(redirect_link, { replace: true });
			// 	return;
			// }

			if (type === "email") {
				navigate("/verify-email", { replace: true });
			} else if (type === "email-cn") {
				navigate("/verify-email-chinese", { replace: true });
			} else if (response.is_register) {
				navigate("/after-signup", { replace: true });
			} else {
				navigate("/home", { replace: true });
			}

			auth.setAuthorizationToken(response.sctoken);
			// navigate("/", { replace: true });
		}
	};

	const emailLogin = async (data) => {
		let response = await auth.login(data.email, data.password);
		if (response.data) {
			setUser(response.data);
			navigate("/", { replace: true });
		} else {
			alert("Username/Password invalid!");
		}
	};

	const logout = () => {
		setUser(null);
		setWalletconnect(null);
		navigate("/", { replace: true });
	};

	const value = useMemo(
		() => ({
			user,
			login,
			logout,
			emailLogin,
			_setUser,
		}),
		[user]
	);

	return (
		<AuthContext.Provider value={value}>{children}</AuthContext.Provider>
	);
};

export const useAuth = () => {
	return useContext(AuthContext);
};
