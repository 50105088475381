import { lazy } from "react";
import { Navigate } from "react-router-dom";

const MainLayout = lazy(() => import("../pages/Layout/Main"));

const Home = lazy(() => import("../pages/Home/Home"));
const Creator = lazy(() => import("../pages/Creator/Creator"));
const Auth = lazy(() => import("../pages/Auth/Auth"));
const Mission = lazy(() => import("../pages/Mission/Mission"));
const CreateMission = lazy(() => import("../pages/Mission/CreateMission"));
const RequirementDetails = lazy(
	() => import("../pages/Mission/addRequirement")
);

const CreateClub = lazy(() => import("../pages/Club/CreateClub"));

function getRoutes() {
	const superchargeRoutes = [
		{
			path: "/auth",
			element: <Auth />,
		},
		{
			path: "/",
			element: <MainLayout />,
			children: [
				{
					path: "/",
					element: <Navigate to={"/creator"} />,
				},
				{
					path: "/home",
					element: <Home />,
				},
				{
					path: "/creator",
					element: <Creator />,
				},
				{
					path: "/mission",
					element: <Mission />,
				},
				{
					path: "/create-mission",
					element: <CreateMission />,
				},
				{
					path: "/add-req",
					element: <RequirementDetails />,
				},
				{
					path: "/create-club",
					element: <CreateClub />,
				},
			],
		},
	];

	return superchargeRoutes;
}

export default {
	getRoutes: getRoutes,
};
